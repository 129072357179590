import React from 'react';

/**
 *  https://mailing.nftstudios.services/client/mmerch/list/15e5c25b59/member
 * {
    "email": "josephjaniga@gmail.com",
    "tags": [
        "website"
    ]
    }
 */

function App() {
  return (
    <div className="App">
      <div className="page" id="subscribe-landing-desktop">
        <div className="modal">
          <h1>stay in the loop</h1>

          <div id="mc_embed_shell">
            <div id="mc_embed_signup">
              <form
                action="https://xyz.us8.list-manage.com/subscribe/post?u=7236497035690609628a45495&amp;id=15e5c25b59&amp;f_id=006d7be0f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_self"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <div className="indicates-required">
                    {/*<span className="asterisk">*</span> indicates required*/}
                  </div>
                  <div className="mc-field-group form">
                    {/*<label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>*/}
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email input"
                      id="mce-EMAIL"
                      required
                      placeholder={'enter your email address'}
                    />
                    <input
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                      value={'submit'}
                    />
                  </div>
                  <div id="mce-responses" className="clear foot">
                    <div
                      className="response"
                      id="mce-error-response"
                      style={{ display: 'none' }}
                    ></div>
                    <div
                      className="response"
                      id="mce-success-response"
                      style={{ display: 'none' }}
                    ></div>
                  </div>
                  {/* honeypot, do not remove */}
                  <div
                    aria-hidden="true"
                    style={{ position: 'absolute', left: '-5000px' }}
                  >
                    <input
                      type="text"
                      name="b_7236497035690609628a45495_15e5c25b59"
                      tabIndex={-1}
                    />
                  </div>
                  <div className="optionalParent">
                    <div className="clear foot">
                      <p style={{ margin: '0px auto' }}>
                        <a href="http://eepurl.com/ivnt1E" title="Mailchimp">
                          <span
                            style={{
                              display: 'inline-block',
                              backgroundColor: 'transparent',
                              borderRadius: '4px',
                            }}
                          >
                            <img
                              className="refferal_badge"
                              src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                              alt="Intuit Mailchimp"
                              style={{
                                width: '220px',
                                height: '40px',
                                padding: '2px 0px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'none',
                              }}
                            />
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <p className="type-s-reg">
                And, no, we won’t sell your email. Ever.
              </p>
            </div>
            <div>
              <p className="emoji">🍱</p>
            </div>
            <div>
              <a
                className="type-s-med modal-footer_link"
                href="http://www.mmERCH.xyz"
                target="_blank"
                rel="noreferrer"
              >
                www.mmERCH.xyz
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
